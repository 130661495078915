import React from 'react';
import logo from "../logo.svg";
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';

function Header(props) {
  return (
    <React.Fragment>
    <header>
      <Container className="top-head">
        <Row className="justify-content-between">
          <Col><img src={logo} alt="logo" className="top-head-logo" /></Col>
          <Col>
            <div className="top-head-link" onClick={props.sentCart}>
              <div>
                <i className="icon ion-ios-cart"></i>
                <span>{props.total} Items<br/>Place Order</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
    <div className="header-mask"></div>
    <div className="clearfix"></div>
    </React.Fragment>
  );
}

export default Header;