import React from 'react';
import { mobile } from '../config';

function Footer() {
  return (
    <footer>
      <a href={'tel:+'+mobile} target="_blank" rel="noopener noreferrer" className="footer-tel"><i className="icon ion-ios-call"></i></a>
    </footer>
  );
}

export default Footer;