import React from 'react';
import logo from "../logo.svg";
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function HeaderTwo() {
  return (
    <React.Fragment>
    <header>
      <Container className="top-head">
        <Row className="justify-content-between">
          <Col><img src={logo} alt="logo" className="top-head-logo" /></Col>
          <Col>
            <Link to="/order" className="top-head-link">
              <div>
                <i className="icon ion-ios-briefcase"></i>
                <span>Return to<br/>Order Page</span>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </header>
    <div className="header-mask"></div>
    <div className="clearfix"></div>
    </React.Fragment>
  );
}

export default HeaderTwo;