import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class Items extends Component {
  addToCart = (id) => {
    this.props.addToCart(id);
  };

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  render() {
    const table = sessionStorage.getItem("table");
    let product = Object.values(this.props.items);
    return (
      <Row>
        {product.map((item, key) => {
          return (
            <Col md={12} className="sale-items" key={key}>
              <Row>
                <Col md={12}>
                  <div className="items">
                    <img
                      src={item.url}
                      alt={item.title}
                      className="category-logo"
                      onClick={() => this.addToCart(item.id)}
                    />
                    <h5 onClick={() => this.addToCart(item.id)} >{item.title}</h5>
                    <p>{item.description}</p>
                    <label>{this.round(item.price)}</label>
                  </div>
                  <div
                    className="add-to-cart"
                    onClick={() => this.addToCart(item.id)}
                  >
                    <i>+</i>
                  </div>
                </Col>
              </Row>
            </Col>
          );
        })}

        <div className="token-fluid">
            <div className="tables">
                <label className={ (table === 'Take away') ? 'take-away' : 'normal' }>{table}</label>
            </div>
        </div>
      </Row>
    );
  }
}
export default Items;
