import React, {Component} from 'react';
import logo from '../logo.svg';
import { 
    Container,
    Row,
    Col
} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { metaTitle } from '../config';

class Home extends Component{

    state = {
        name: '',
        tel: '',
        selected: 'Take away',
        login: false
    }

    componentDidMount() {
        const { match:{params} } = this.props;
        let table = this.state.selected;
        if(params.ID) {
            table = params.ID;
            this.setState({
                selected: table
            });
        }
        sessionStorage.setItem('table', table);
    }

    onHandleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ metaTitle('Home') }</title>
                </Helmet>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={5} sm={4}>
                            <div className="home-fluid">
                                <img src={logo} alt="logo" className="home-logo" />
                                <div className="tables">
                                    <label className={ (this.state.selected === 'Take away') ? 'take-away' : 'normal' }>{this.state.selected}</label>
                                </div>
                                <Link className="home-link" to="/order">Order Now</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
export default Home;