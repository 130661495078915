import React, { Component } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { api_base, currency } from '../config';

class Items extends Component {
  state = {
    items: [],
    value: 'add'
  };

  componentDidMount() {
    const cart = sessionStorage.getItem("cart");
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    } else {
      this.setState({ null_item: true });
    }
    let id = "";
    cart_item.forEach((items) => {
      id += items.id + "-";
    });

    if (id !== "") {
      id = id.slice(0, -1);
    }

    axios
      .post(
        api_base + "/menu/multi_items",
        {
          items: id,
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      )
      .then((response) => {
        const datas = response.data;
        if (datas.status === true) {
          let it = Object.values(datas.result);
          this.setState({ items: it });
          this.props.addItems(it);
        }
      })
      .catch((response) => {
        this.setState({ items: [] });
      });
  }

  deleteItem = (item, key) => {
    let items = this.state.items;
    items.splice(key, 1);
    this.setState({ items: items });
    this.props.deleteItem(item);
  }
  
  render() {
    let product = this.state.items;
    let i_count = 0;
    let i_total = 0;
    let i_note = '';
    let s_total = 0;
    let cart = this.props.cart_item;

    return (
      <Row>
        {
          product.map((item, key) => {
            cart.forEach((cart_item) => {
              if(item.id === cart_item.id){
                i_count = cart_item.count;
                i_note = cart_item.note;
                return true;
              }
            });
            i_total = (i_count*item.price);
            s_total += i_total;
            return (
              <Col md={12} className="sale-items unset-height" key={key}>
                <Row>
                  <Col md={12}>
                    <div className="sale-items-final">
                      <div className="items">
                        <img
                          src={item.url}
                          alt={item.title}
                          className="category-logo"
                        />
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        <label>{this.props.round(i_total)}</label>
                        <label className="no-after">
                          ({this.props.round(item.price)}x{i_count})
                        </label>
                      </div>
                      <div className="remove-from-cart">
                        <i onClick={() => this.deleteItem(item.id, key)} className="icon ion-ios-trash"></i>
                      </div>
                    </div>
                    <div className="sale-items-support">
                      <button onClick={() => this.props.decrement(item.id)}>
                        -
                      </button>
                      <input type="text" value={i_count} readOnly />
                      <button onClick={() => this.props.increment(item.id)}>
                        +
                      </button>
                      <textarea
                        className=""
                        name={item.id}
                        placeholder="Note:"
                        value={i_note}
                        onChange={this.props.addNote}
                      />
                    </div>
                  </Col>
                  <div className="clearfix"></div>
                </Row>
              </Col>
            );
          })
        }
        
        <Col md={12} className="sale-items">
          <Row>
            <Col md={12}>
              <div className="cart-clear" onClick={this.props.clear}>
                <i className="icon ion-ios-close"></i>
                Clear
              </div>
              <div className="cart-total">
                Total Amount:
                <br />
                <strong>{this.props.round(s_total)} {currency}</strong>
              </div>
            </Col>
          </Row>
        </Col>

        <div className="token-fluid">
            <div className="tables">
                <label className={ (this.props.table === 'Take away') ? 'take-away' : 'normal' }>{this.props.table}</label>
            </div>
        </div>
      </Row>
    );
  }
}
export default Items;
